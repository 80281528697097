import firebase from 'firebase/compat/app'
import API2 from 'api2'

export const loginAction = (email, password) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      API2.post('auth/login', { email, password })
        .then((res) => {
          if (res.data.data.accessToken) {
            localStorage.setItem('user', JSON.stringify(res.data.data));
            localStorage.setItem('accessToken', res.data.data.accessToken);
            localStorage.setItem('userId', res.data.data._id);
            let data = res.data.data;
            dispatch({ type: 'login', payload: true, data });
            const config = {
              headers: {
                Authorization: res.data.data.accessToken,
              },
            };
            API2.get('api/user/profile', config)
              .then((res1) => {
                localStorage.setItem('profile', JSON.stringify(res1.data.data));
                localStorage.setItem(
                  'announcement',
                  JSON.stringify(res1.data.hasAlert)
                );
                let data = res1.data.data;
                let stats = res1.data;
                let orgData = res1.data.orgData;
                localStorage.setItem(
                  'orgProfile',
                  JSON.stringify(res1.data.orgData)
                );
                dispatch({
                  type: 'GET_PROFILE_SUCCESS',
                  payload: true,
                  data,
                  usedStorage: res1.data.usedStorage,
                  stats,
                  orgData,
                });
                resolve(data); // Resolve the promise with the data
              })
              .catch((err) => {
                let errMessage =
                  (err.response && err.response.data.message) ||
                  'Unknown Error Occured';
                dispatch({
                  type: 'GET_PROFILE_FAILED',
                  payload: 'failed',
                  err: errMessage,
                });
                reject(errMessage); // Reject the promise with the error message
              });
          } else if (res.data.data.name) {
            localStorage.setItem('user', JSON.stringify(res.data.data));
            let data = res.data.data;
            dispatch({ type: 'login', payload: true, data });
            resolve(data); // Resolve the promise with the data
          } else {
            const errorMessage = 'An Error Occured. Please contact support.';
            dispatch({
              type: 'login_failed',
              payload: 'failed',
              err: errorMessage,
            });
            reject(errorMessage); // Reject the promise with the error message
          }
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({
            type: 'login_failed',
            payload: 'failed',
            err: errMessage,
          });
          reject(errMessage); // Reject the promise with the error message
        });
    });
  };
};

export const resetPassword = (email) => {
  return (dispatch, getState, {getFirestore}) => {
    firebase.auth().sendPasswordResetEmail(email).then((res) => {
      dispatch({ type: "password_reset_success", payload: "success", res });
    }).catch((err) => {
      dispatch({ type: "password_reset_error", payload: "failed", err });
    });
  }
};

export const tokenExpire = () => {
  return (dispatch) => {
    dispatch({ type: "TOKEN_EXPIRE", message: "Session expired"})
  }
}