import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import OrgLogo from '../../../assets/img/brand/Upskillr.png';
import { loginAction } from 'actions/loginAction';

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from 'reactstrap';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const profile = useSelector((state) => state.authState.user);

  const [authError, setAuthError] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [focusedEmail, setFocusedEmail] = useState(false);
  const [focusedPassword, setFocusedPassword] = useState(false);

  const { location } = history;
  let redirectPath = location?.state?.redirectPath || null;

  const handleLogin = () => {
    dispatch(loginAction(email, password))
      .then((res) => {
        if (res) {
          if (res.userType === 'admin') {
            history.push('/users');
          } else {
            history.push('/dashboard');
          }
        }
      })
      .catch((err) => {
        setAuthError(err);
      });
  };

  const keyPressed = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  if (profile && !redirectPath) {
    if (profile.accessToken && profile.userType === 'admin') {
      return <Redirect to="/users" />;
    }
    return null;
  } else if (profile && redirectPath) {
    return <Redirect to={redirectPath === '/' ? '/users' : redirectPath} />;
  }

  return (
    <div className="login-wrapper">
      <Card className="bg-white border-0 mb-0 card-border">
        <CardBody className="px-lg-4 py-lg-4">
          <div className="d-flex align-items-center justify-content-center my-3">
            <img src={OrgLogo} alt="Organization Logo" className="org-logo" />
          </div>
          <div className="text-center text-muted mb-4">
            <small>Sign in with credentials</small>
          </div>
          <Form role="form">
            <FormGroup
              className={classnames('mb-3', { focused: focusedEmail })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  type="email"
                  value={email}
                  onFocus={() => setFocusedEmail(true)}
                  onBlur={() => setFocusedEmail(false)}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup className={classnames({ focused: focusedPassword })}>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  type="password"
                  value={password}
                  onKeyPress={keyPressed}
                  onFocus={() => setFocusedPassword(true)}
                  onBlur={() => setFocusedPassword(false)}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </InputGroup>
            </FormGroup>

            <div className="text-center">
              <Button color="default" type="button" onClick={handleLogin}>
                Sign in
              </Button>
              {authError && (
                <span className="text-danger">
                  <p>{authError}</p>
                </span>
              )}
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default Login;
